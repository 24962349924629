import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import brittany from "../images/brittany-wrigley.jpg"
import avma from "../images/avma.png"
import isvma from "../images/isvma.jpg"
import vbma from "../images/vbma-logo-white.png"
import ama from "../images/ama.png"
import Fade from 'react-reveal/Fade';

const SecondPage = () => (
  <Layout>
    <SEO title="About Medical Writing" description="Learn about pet parent education, professional materials, and medical research. "/>
    <div className="hero hero-subpage dogs">
    <Fade bottom cascade>

      <div className="container p-0 text-center">
        <div className="hero-text-heading">ABOUT ME</div>

      </div>
      </Fade>
    </div>
    <div className="container py-5">
      <div className="row">
        <div className="col-md-12 py-5">

          <p>
          Clients need accurate and engaging medical content that speaks to their target audiences. Readers need
to feel empowered by having knowledge and understanding of the content they are given. With
experience in veterinary medicine and research, I have the knowledge and expertise necessary to clearly
and accurately communicate your topic of choice to any target audience. Understanding what speaks to
readers is essential in creating meaningful content.          </p>
          <p>
          I hold my work to the highest standard to ensure my clients are receiving exactly what they are asking
for while respecting deadlines.<b> When working with me, you can feel confident in receiving high-quality
deliverables that are accurate and engaging every time!      </b>    </p>

      
        </div>
      </div>
    </div>

  </Layout>
)

export default SecondPage
